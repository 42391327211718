import React from "react";
import "./SignUpPage.scss";
import SocialLogin from "../../components/login/SocialLogin";
function SignUpPage() {
  return (
    <>
      <SocialLogin />
    </>
  );
}

export default SignUpPage;
